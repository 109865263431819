#root {
  height: 100vh;
  width: 100vw;
}

.HtmlCompare {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.HtmlCompare-drop {
  display: flex;
  flex-direction: row;
  flex: 1 1 1em;
  margin: 2px;
}

.HtmlCompare-drop .lhs {
  background: #FFDDBB;
  color: #442200;
  flex: 1 1 33vh;
  border: solid 1px #442200;
  border-right: solid 1px #333322;
  resize: none;
}

.HtmlCompare-drop .rhs {
  background: #BBFFDD;
  color: #004422;
  flex: 1 1 33vh;
  border: solid 1px #004422;
  border-left: none;
  resize: none;
}

.HtmlCompare-inputs {
  display: flex;
  flex-direction: row;
  flex: 1 1 50%;
  margin: 2px;
}

.HtmlCompare-inputs #lhs {
  background: #FFDDBB;
  color: #442200;
  flex: 1 1 33vh;
  border: solid 1px #442200;
  border-right: solid 1px #333322;
  resize: none;
}

.HtmlCompare-inputs #rhs {
  background: #BBFFDD;
  color: #004422;
  flex: 1 1 33vh;
  border: solid 1px #004422;
  border-left: none;
  resize: none;
}

.HtmlCompare-controls {
  flex: 1 1 1em;
  border: solid 1px #888888;
  margin: 2px;
}

.HtmlCompare-forward: {
  margin: none;
  padding: none;
  border: none;
}

.HtmlCompare-reverse: {
  margin: none;
  padding: none;
  border: none;
}

.HtmlCompare-output {
  overflow: scroll;
  font-family: monospace;
  white-space: pre;
  flex: 1 1 50%;
  border: solid 1px #444444;
  margin: 2px;
}

.HtmlCompare-output .HtmlCompare-plain {
  color: #000000;
}

.HtmlCompare-output .HtmlCompare-forward .HtmlCompare-missing {
  color: #006600;
  font-weight: bold;
}

.HtmlCompare-output .HtmlCompare-reverse .HtmlCompare-missing {
  color: #AA0000;
  font-weight: bold;
}

.HtmlCompare-output .HtmlCompare-changed {
  color: #00AAAA;
  font-weight: bold;
}

.HtmlCompare-output .HtmlCompare-mismatch {
  color: #004488;
  font-weight: bold;
}

.HtmlCompare-output .HtmlCompare-debug {
  display: none;
}
